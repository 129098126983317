import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import { mdiClose, mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import FocusTrap from 'focus-trap-react';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from '../../common/enums';
import { defaultColors } from '../../theme/colors';
import { theme } from '../../theme/theme';
import { getParagraph } from '../../utils/paragraphHelpers';
import LanguageSwitcher from '../LanguageSwitcher';
import SignUpForm from '../forms/SignUpForm';
import ParagraphSearchForm from '../paragraphs/ParagraphSearchForm';
import SkipLink from '../screen_reader/SkipLink';

const StyledSignUp = styled.div`
  display: inline-flex;
  width: max-content;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledHeader = styled.header`
  width: 100%;
  background-color: ${props => props.color || '#FFFFFF'};
  border-bottom: 2px solid ${defaultColors.border};
  .navbar-expand .navbar-nav .dropdown-menu {
    position: relative;
  }
  .col-nav {
    position: fixed;
    top: 95px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    border-top: 1px solid ${defaultColors.border};
    transition: height 0.3s ease;
    > div {
      height: 100%;
      padding-bottom: 1rem;
    }
  }

  .col-nav.show {
    display: block !important;
  }

  .logo {
    width: 100%;
    max-height: 350px;

    @media screen and (min-width: ${theme.media.lg}) and (max-width: 1199px) {
      width: 150px;
      height: 150px;
      margin-left: -30px;
    }

    @media screen and (min-width: ${theme.media.xl}) and (max-width: 1399px) {
      width: 200px;
      height: 200px;
      margin-left: -50px;
    }
  }

  @media (min-width: ${theme.media.lg}) {
    .col-nav {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      border-top: none;
      > div {
        padding-bottom: 0;
      }
    }

    background-color: ${props => props.color || '#FFFFFF'};
    border-bottom: 2px solid ${defaultColors.border};
    .header-top {
      background-color: ${defaultColors.primary};
      .row {
        height: 58px; // This hardcodes the Util Menu's height.
      }
      ul.menu {
        a,
        a:hover,
        a:active,
        a:focus {
          color: #fff;
        }
        li {
          display: flex;
          align-items: center;
          &:last-child::after {
            display: none;
          }
        }
      }
    }

    .search-visible {
      > div > div:not(.search) {
        display: none;
      }
    }
  }
`;

interface HeaderProps {
  language: Languages;
  translations: Translations;
  serverData?: [];
}

const Header: React.FC<HeaderProps> = function ({ language, translations, serverData }) {
  const [showSearch, setShowSearch] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const searchRef = React.useRef(null);

  React.useEffect(() => {
    const closeMenu: React.EventHandler<any> = (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowMenu(false);
        setShowSearch(false);
      }
    };
    if (showMenu) {
      window.addEventListener('keydown', closeMenu);
    }
    return () => window.removeEventListener('keydown', closeMenu);
  }, [showMenu, showSearch]);

  const data = useStaticQuery(graphql`
    {
      allStorageHeader {
        edges {
          node {
            langcode
            behaviors {
              entity_variant {
                theme
              }
            }
            relationships {
              headerTop: field_header_top {
                type: __typename
                ...ParagraphMenu
                ...ParagraphSearchForm
                ...ParagraphPlaceholder
              }
              headerBottom: field_header_bottom {
                type: __typename
                ...ParagraphLinkedLogo
                ...ParagraphNavigation
                ...ParagraphSearchForm
                ...ParagraphPlaceholder
              }
            }
          }
        }
      }
    }
  `);

  const header = data.allStorageHeader.edges.find(({ node }) => node.langcode === language);

  const headerTopParagraphs: [Paragraph] = header?.node.relationships?.headerTop;

  const topMenuLinks = headerTopParagraphs.find(paragraph => paragraph.type === 'paragraph__menu');

  const headerBottomParagraphs: [Paragraph] = header?.node.relationships?.headerBottom;

  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState(false);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <Helmet bodyAttributes={{ class: showMenu && 'overflow-hidden' }} />
      <StyledHeader role="banner" className="sticky-top no-print">
        <SkipLink
          style={{ top: '0.5rem', left: '0.5rem' }}
          href="#mainContent"
          className="position-absolute"
        >
          Skip to Main Content
        </SkipLink>
        <FocusTrap active={showMenu} focusTrapOptions={{ returnFocusOnDeactivate: true }}>
          <div role={showMenu ? 'dialog' : undefined} aria-modal={showMenu}>
            {headerTopParagraphs && (
              <Container className="header-top d-none d-lg-block" fluid>
                <Container fluid="lg" className="justify-content-between">
                  <Row className="align-items-center justify-content-lg-end">
                    <StyledSignUp>
                      <span onClick={handleOpen}>{t('Sign Up')}</span>
                      <SignUpForm show={showModal} handleClose={handleClose} />
                    </StyledSignUp>
                    {headerTopParagraphs.map(component => {
                      return (
                        <React.Fragment key={component.id}>
                          {getParagraph({
                            node: component,
                            props: { showSearch, setShowSearch, showMenu, setShowMenu, searchRef },
                            translations,
                          })}
                        </React.Fragment>
                      );
                    })}
                    <div className="col col-auto">
                      <LanguageSwitcher
                        language={language}
                        translations={translations}
                        serverData={serverData}
                      />
                    </div>
                  </Row>
                </Container>
              </Container>
            )}

            {headerBottomParagraphs && (
              <Navbar
                className={cx('p-0', showSearch ? 'search-visible' : '')}
                style={{ height: '95px', overflow: 'hidden' }}
              >
                <Container fluid="lg justify-content-center">
                  <ParagraphSearchForm
                    node={{
                      behavior_settings: { decoupled_styles: { classes: [' order-2 d-lg-none'] } },
                    }}
                    props={{ showSearch, setShowSearch, showMenu, searchRef, showCancel: true }}
                  />
                  <Button
                    variant="link"
                    aria-label={!showMenu ? 'Show menu' : 'Close menu'}
                    aria-expanded={showMenu}
                    aria-controls="mainMenu"
                    onClick={() => setShowMenu(!showMenu)}
                    className={cx(
                      'rounded-pill p-2 d-lg-none ms-2 text-decoration-none justify-content-center order-3',
                      showMenu && 'px-3',
                      showSearch ? 'd-none' : 'd-inline-flex',
                    )}
                    style={{
                      height: '48px',
                      width: showMenu ? 'auto' : '48px',
                      color: theme[CMS_THEME.NEUTRAL].text.default,
                      backgroundColor: defaultColors.subNavBg,
                    }}
                  >
                    {showMenu && <span className="d-inline-block me-2">Close</span>}
                    <Icon
                      path={!showMenu ? mdiMenu : mdiClose}
                      size={1}
                      color="inherit"
                      aria-hidden
                    />
                  </Button>

                  {headerBottomParagraphs.map(node => (
                    <React.Fragment key={node.id}>
                      {getParagraph({
                        node,
                        props: {
                          showSearch,
                          setShowSearch,
                          showMenu,
                          setShowMenu,
                          searchRef,
                          topMenuLinks,
                        },
                        translations,
                      })}
                    </React.Fragment>
                  ))}
                </Container>
              </Navbar>
            )}
          </div>
        </FocusTrap>
      </StyledHeader>
    </>
  );
};

export default Header;
