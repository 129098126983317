/**
 * Global functions that are used in both /src and /plugins
 **/

import { ISendIt, IStopPoint } from '../types';
import { pointTypes } from './constants';

/**
 * Runs a list of regex strings against an item and tells you if that item matches anything in the regex items
 **/
export const hasRouteException = (expressions: string[], item: string) => {
  const mergedExpressions = new RegExp(expressions.join('|'), 'i');
  let matchesAnyExpression = false;

  if (mergedExpressions.test(item)) {
    matchesAnyExpression = true;
  }

  return matchesAnyExpression;
};

/**
 * returns as string index of total
 **/
const getPositionFromSiblings = (totalSiblings?: number, index?: number) => {
  if (!totalSiblings || !index) return '1 of 1';

  return `${index + 1} of ${totalSiblings}`;
};

/**
 * Replaces any parts of an item that match expressions with the replaceWith arg
 **/
export const replaceRouteExceptions = (
  expressions: string[],
  item: string,
  replaceWith: string
) => {
  const mergedExpressions = new RegExp(expressions.join('|'), 'i');
  return item.replace(mergedExpressions, replaceWith);
};

/**
 * Lower cases values in an object
 **/
export const toLowerParams = (params?: { [key: string]: string | undefined }) => {
  if (!params) return {};

  const entries = Object.entries(params);
  const newObj: { [key: string]: string } = {};

  entries.forEach(entry => {
    if (entry[1] !== undefined) {
      newObj[entry[0]] = entry[1].toLowerCase();
    } else {
      newObj[entry[0]] = '';
    }
  });

  return newObj;
};

/**
 * Sends google analytics data via purData
 **/
export const sendIt = ({
  event,
  eventCategory,
  eventAction,
  eventLabel,
  eventParams,
  eventCallback,
}: ISendIt) => {
  const dataLayerName = process.env.GATSBY_DATA_LAYER_NAME || 'dataLayer';
  // Log for testing sendIt events as they come through
  if (process.env.GATSBY_GA_LOGGING === 'true') {
    console.groupCollapsed(`%cGA Event: ${eventCategory}`, 'color: #ffb300');
    console.log({
      dataLayerName,
      event: { event, eventCategory, eventAction, eventLabel, eventParams },
    });
    console.groupEnd();
  }
  return window[dataLayerName]?.push(
    {
      event: event?.toLowerCase(),
      eventCategory: eventCategory?.toLowerCase(),
      eventAction: eventAction?.toLowerCase(),
      eventLabel: eventLabel?.toLowerCase(),
      eventCallback,
      event_params: toLowerParams(eventParams),
    },
    { event_params: undefined }
  );
};

export const generateGaEvent = (stopPoints: IStopPoint[], keyTarget: any) => {
  const pointComponent = stopPoints.find(point => point.type === pointTypes.component);
  const pointModule = stopPoints.find(point => point.type === pointTypes.module);
  const pointPage = stopPoints.find(point => point.type === pointTypes.page);

  sendIt({
    event: pointComponent?.eventOverride ?? 'select_content',
    eventCategory:
      pointComponent?.category ??
      (stopPoints.find(i => i.type === pointTypes.module)?.typeLabel.replace('storage__', '') ||
        '[not set]'),
    eventAction: pointComponent?.action ?? keyTarget?.innerText ?? stopPoints[0].label,
    eventLabel: pointComponent?.eventLabel ?? keyTarget?.innerText ?? stopPoints[0].label,
    eventParams: {
      pageType: pointPage?.pageType.replace('node__', ''),
      moduleId: pointModule?.label || '[not set]',
      moduleTitle: pointModule?.title || '[not set]',
      modulePosition: getPositionFromSiblings(pointModule?.totalSiblings, pointModule?.index),
      moduleType: pointModule?.typeLabel.replace('storage__', '') || '[not set]',
      componentId:
        pointComponent?.eventLabel ?? pointComponent?.label ?? pointModule?.label ?? '[not set]',
      componentTitle: pointComponent?.title || '[not set]',
      componentPosition: getPositionFromSiblings(
        pointComponent?.totalSiblings,
        pointComponent?.index
      ),
      componentType:
        pointComponent?.typeLabel.replace('storage__', '') ??
        pointModule?.typeLabel.replace('storage__', '') ??
        '[not set]',
      linkTitle: keyTarget?.innerText || '[not set]',
      linkUrl: keyTarget?.href || '[not set]',
      ...pointModule?.additionalParams,
      ...pointComponent?.additionalParams,
    },
  });
};

export function pollObjectOnWindow(objectName, interval, maxAttempts, attempts = 0) {
  return new Promise((resolve, reject) => {
    if (window[objectName]) {
      resolve(window[objectName]);
    } else if (maxAttempts && attempts >= maxAttempts) {
      reject(new Error(`Failed to find object '${objectName}' on the window.`));
    } else {
      setTimeout(() => {
        pollObjectOnWindow(objectName, interval, maxAttempts, attempts + 1)
          .then(resolve)
          .catch(reject);
      }, interval);
    }
  });
};

export const dlEventSend = (dlEvent:Object|undefined) => {
  const dataLayerName = process.env.GATSBY_DATA_LAYER_NAME || 'dataLayer';
  if (dlEvent) window['dataLayer']?.push(dlEvent, {event_params: undefined});
};
