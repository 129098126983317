import { styled } from '@linaria/react';
import axios from 'axios';
import { dlEventSend } from 'gatsby-plugin-purina-analytics/common/functions';
import DOMPurify from 'isomorphic-dompurify';
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface WebformField {
  '#type': string;
  '#title': string;
  '#required'?: boolean;
  '#options'?: Record<string, string>;
}

interface SignUpFormProps {
  show: boolean;
  handleClose: () => void;
}

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
  }
`;

const StyledTitle = styled.h2`
  font-size: 2rem;
`;

const StyledDescription = styled.div`
  p {
    font-size: 1.375rem;
    font-weight: 200;
    margin-bottom: 2rem;
  }
`;

const SignUpForm: React.FC<SignUpFormProps> = ({ show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language || 'en';

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fields, setFields] = useState<Record<string, WebformField>>({});
  const [description, setDescription] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastVariant, setToastVariant] = useState<'success' | 'danger'>('success');
  const [showToast, setShowToast] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const dlEvent = {
    event: 'sign_up',
    event_params: {
      method: 'sign up form',
    },
  };

  // Fetch fields from Drupal
  useEffect(() => {
    const fetchFields = async () => {
      try {
        const url =
          currentLang === 'en'
            ? `${process.env.GATSBY_DRUPAL_ENDPOINT}webform_rest/salesforce_signup/fields?_format=json`
            : `${process.env.GATSBY_DRUPAL_ENDPOINT}${currentLang}/webform_rest/salesforce_signup/fields?_format=json`;

        const response = await axios.get(url);
        setFields(response.data);

        const layoutContainer = response.data.layout_container;
        if (layoutContainer && layoutContainer.markup && layoutContainer.markup['#markup']) {
          setDescription(layoutContainer.markup['#markup']);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching form fields:', error);
        setLoading(false);
      }
    };

    if (show) fetchFields();
  }, [show, currentLang]);

  // Form submission handler
  const onSubmit: SubmitHandler<Record<string, any>> = async data => {
    try {
      setIsSubmitting(true);

      const url =
        currentLang === 'en'
          ? `${process.env.GATSBY_DRUPAL_ENDPOINT}webform_rest/submit?_format=json`
          : `${process.env.GATSBY_DRUPAL_ENDPOINT}${currentLang}/webform_rest/submit?_format=json`;

      await axios.post(
        url,
        {
          webform_id: 'salesforce_signup',
          ...data,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.GATSBY_DRUPAL_API_KEY,
          },
        },
      );

      setToastMessage(t('Form submitted successfully!'));
      setToastVariant('success');
      setShowToast(true);

      dlEventSend(dlEvent);

      reset();
      handleClose();
    } catch (error) {
      console.error('Error submitting the form:', error);

      setToastMessage(t('There was an issue submitting the form. Please try again later.'));
      setToastVariant('danger');
      setShowToast(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render form fields dynamically
  const renderField = (fieldKey: string, field: WebformField | null) => {
    if (!field) {
      console.warn(`Field is null or undefined for key: ${fieldKey}`);
      return null;
    }

    const fieldType = field['#type'];
    const selectedProfessionalRole = watch('professional_role');
    const selectedProfessionalSector = watch('professional_sector');

    switch (fieldType) {
      case 'textfield':
        if (fieldKey === 'specialization' && selectedProfessionalRole !== 'Specialist') {
          return null;
        }

        return (
          <Form.Group className="mb-3" key={fieldKey}>
            <Form.Label>{t(field['#title'])}</Form.Label>
            <Form.Control
              type="text"
              {...register(fieldKey, {
                required: field['#required'] && `${t(field['#title'])} ${t('is required')}`,
              })}
              isInvalid={!!errors[fieldKey]}
            />
            <Form.Control.Feedback type="invalid">
              {errors[fieldKey]?.message as string}
            </Form.Control.Feedback>
          </Form.Group>
        );

      case 'textarea':
        if (fieldKey === 'other_sector' && selectedProfessionalSector !== 'Other') {
          return null;
        }

        return (
          <Form.Group className="mb-3" key={fieldKey}>
            <Form.Label>{t(field['#title'])}</Form.Label>
            <Form.Control
              as="textarea"
              {...register(fieldKey, {
                required: field['#required'] && `${t(field['#title'])} ${t('is required')}`,
              })}
              isInvalid={!!errors[fieldKey]}
            />
            <Form.Control.Feedback type="invalid">
              {errors[fieldKey]?.message as string}
            </Form.Control.Feedback>
          </Form.Group>
        );

      case 'webform_email_confirm':
        return (
          <>
            <Form.Group className="mb-3" key={fieldKey}>
              <Form.Label>{t(field['#title'])}</Form.Label>
              <Form.Control
                type="email"
                {...register(fieldKey, {
                  required: field['#required'] && `${t(field['#title'])} ${t('is required')}`,
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: t('Invalid email address'),
                  },
                })}
                isInvalid={!!errors[fieldKey]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[fieldKey]?.message as string}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" key={`${fieldKey}_confirm`}>
              <Form.Label>{`${t('Confirm')} ${t(field['#title'])}`}</Form.Label>
              <Form.Control
                type="email"
                {...register(`${fieldKey}_confirm`, {
                  required: `${t('Confirm')} ${t(field['#title'])} ${t('is required')}`,
                  validate: value => value === watch(fieldKey) || t('Email addresses do not match'),
                })}
                isInvalid={!!errors[`${fieldKey}_confirm`]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[`${fieldKey}_confirm`]?.message as string}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        );

      case 'select':
        return (
          <Form.Group className="mb-3" key={fieldKey}>
            <Form.Label>{t(field['#title'])}</Form.Label>
            <Form.Select
              {...register(fieldKey, {
                required: field['#required'] && `${t(field['#title'])} ${t('is required')}`,
              })}
              isInvalid={!!errors[fieldKey]}
            >
              <option value="">{t('Select an option')}</option>
              {field['#options'] &&
                Object.entries(field['#options']).map(([value, label]) => (
                  <option value={value} key={value}>
                    {t(label)}
                  </option>
                ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors[fieldKey]?.message as string}
            </Form.Control.Feedback>
          </Form.Group>
        );

      case 'radios':
        return (
          <Form.Group className="mb-3" key={fieldKey}>
            <Form.Label>{t(field['#title'])}</Form.Label>
            {field['#options'] &&
              Object.entries(field['#options']).map(([value, label]) => (
                <Form.Check
                  type="radio"
                  key={value}
                  label={t(label)}
                  value={value}
                  {...register(fieldKey, {
                    required: field['#required']
                      ? `${t(field['#title'])} ${t('is required')}`
                      : false,
                  })}
                  isInvalid={!!errors[fieldKey]}
                />
              ))}
            {errors[fieldKey] && (
              <div className="invalid-feedback d-block">{errors[fieldKey]?.message as string}</div>
            )}
          </Form.Group>
        );

      case 'checkbox':
        return (
          <Form.Group className="mb-3" key={fieldKey}>
            <Form.Check
              type="checkbox"
              label={
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(t(field['#title'])),
                  }}
                />
              }
              {...register(fieldKey, { required: field['#required'] })}
              isInvalid={!!errors[fieldKey]}
            />
            <Form.Control.Feedback type="invalid">
              {errors[fieldKey]?.message as string}
            </Form.Control.Feedback>
          </Form.Group>
        );

      default:
        console.warn(`Unsupported field type: ${fieldType}`);
        return null;
    }
  };

  return (
    <>
      <StyledModal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <StyledTitle className="text-center w-100">
            {t('Get the latest science in your mailbox')}
          </StyledTitle>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>{t('Loading...')}</p>
          ) : (
            <>
              <StyledDescription
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t(description)),
                }}
              />
              <Form onSubmit={handleSubmit(onSubmit)}>
                {fields.layout_container ? (
                  Object.entries(fields.layout_container).map(([key, field]) =>
                    renderField(key, field),
                  )
                ) : (
                  <p>{t('No fields available')}</p>
                )}
                <Form.Group className="mb-3 text-center">
                  <Button
                    variant="danger"
                    type="submit"
                    style={{ backgroundColor: '#e91c24' }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? `${t('Sign Up')}...` : t('Sign Up')}
                  </Button>
                </Form.Group>
              </Form>
            </>
          )}
        </Modal.Body>
      </StyledModal>

      <ToastContainer position="top-center" className="p-3">
        <Toast
          bg={toastVariant}
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Body className="text-white">{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default SignUpForm;
