import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import { typography } from '../../theme/typography';
import { ButtonLink } from '../button/Button';
import SignUpForm from '../forms/SignUpForm';

/* TODO: revisit rich text once all components are re-themed and make cmsTheme required to be passed
 * change color to use the passed theme
// check to see if typography variables are actually working
 */
const StyledRichText = styled.div<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme]?.text?.default};
  font-family: ${typography.body.fontFamily};
  font-size: ${typography.body.fontSize};
  font-weight: normal;
  line-height: ${typography.body.lineHeight};
  letter-spacing: normal;
  overflow-x: auto;

  blockquote {
    background: ${({ cmsTheme }) => theme[cmsTheme]?.background.paper};
    border-left: ${({ cmsTheme }) => `2px solid ${theme[cmsTheme]?.text.default}`};
    padding: 1rem 1rem 1rem 2rem;
    margin-left: 2rem;
    margin-right: 2rem;

    *:last-child {
      margin-bottom: 0;
    }
  }

  .text-align-center {
    text-align: center;
  }

  .text-align-right {
    text-align: right;
  }

  & > * + * {
    margin-block: 1em;
  }

  & h1 {
    font-weight: 400;
    line-height: 1.125;
  }

  & h2 {
    font-weight: 200;
    line-height: 1.125;
  }

  & h3 {
    font-weight: 200;
    line-height: 1.125;
  }

  & h4 {
    font-weight: 200;
  }

  & h5 {
    font-weight: 200;
  }

  & h6 {
    font-weight: 200;
  }

  ul,
  ol {
    margin-left: 3rem;

    ul {
      margin-left: 0;
      padding-left: 1rem;
    }

    ol {
      margin-left: 0;
      padding-left: 1.3rem;
    }

    li {
      line-height: 2;
      padding-left: 1rem;
    }
  }

  a {
    color: ${({ cmsTheme }) => theme[cmsTheme]?.action};

    &:hover,
    &:focus,
    &:active {
      color: ${({ cmsTheme }) => theme[cmsTheme]?.action};
    }
  }

  table {
    margin-bottom: 16px;
    width: 100% !important;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #767676;

    > * > * > * {
      border: 1px solid #767676;
    }

    tr {
      background-color: #C3D3E5;

      th {
        background-color: transparent;
        font-weight: 700;
        padding: 8px;
        text-align: left;
        vertical-align: inherit;

        p {
          margin-bottom: 0;
        }
      }
    }

    tbody {
      tr {
        td {
          font-weight: 400;
          padding: 8px;
          text-align: inherit;
          vertical-align: inherit;

          p {
            margin-bottom: 0;
          }
        }
        &:nth-child(odd) {
          background-color: #fff;
        }
      }
    }
  }

  [data-tooltip] {
    position: relative;
    border-bottom: 2px dashed #e91c24;
  }

  [data-tooltip]::after {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    content: attr(data-tooltip);
    font-size: 0.8rem;
    border-radius: 0.375rem
    left: 50%;
    top: 50%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
    background-color: #fff;
    z-index: 9999;
    padding: 8px;
    width: 300px;
    transform: translate(-50%, -50%);
  }

  [data-tooltip]:hover::after {
    opacity: 1;
  }

  @media print {
    font-size: 0.8rem;

    h1,
    h1 strong {
      font-size: 44px !important;
      font-weight: 700!important;
      margin-bottom: 16px !important;
      margin-top: 16px !important;
    }

    &.is-article > p:first-of-type {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.8rem !important;
      line-height: 1.6 !important;
    }

    ul,
    ol {
      margin-left: 0rem;
  
      ul {
        padding-left: 0.8rem;
      }
  
      ol {
        padding-left: 0.8rem;
      }
  
      li {
        padding-left: 0.8rem;
      }
    }
  
    li {
      font-size: 0.8rem !important;
      line-height: 1.6 !important;
    }
  }
  & .is-video {
   h1 > strong {
      font-weight: 700;
      line-height: 1.125;
      font-size: 1.9rem !important;
   }
  }
`;

type Props = {
  body: string;
  className?: string;
  cmsTheme?: CMS_THEME;
};

const RichText = ({ body = '', className, cmsTheme = CMS_THEME.LIGHT }: Props) => {
  const signUpStr = '<p>&lt;span data-id="sign-up"&gt;&lt;/span&gt;</p>';
  const includeSignUpBtn = body && body.includes(signUpStr);
  const removedSignUpBody = body && body.replace(signUpStr, '');
  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState(false);
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <StyledRichText
        cmsTheme={cmsTheme}
        className={cx(className, 'richText')}
        dangerouslySetInnerHTML={{ __html: removedSignUpBody }}
      />

      {includeSignUpBtn && (
        <div>
          <ButtonLink variant="btn" cmsTheme={CMS_THEME.LIGHT} onClick={handleOpen}>
            {t('Sign Up')}
          </ButtonLink>
          <SignUpForm show={showModal} handleClose={handleClose} />
        </div>
      )}
    </>
  );
};

export default RichText;
