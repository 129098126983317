import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { cx } from '@linaria/core';
import React from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import { dlEventSend } from 'gatsby-plugin-purina-analytics/common/functions';

type Props = {
  facet: FacetLink;
  id: string;
  onChange: React.EventHandler<any>;
  filterCategory: string;
  sortingOption: string;
};

const FacetItem: React.FC<Props> = function (props) {
  const { facet, id, onChange, filterCategory, sortingOption } = props;
  const classes: string[] = [];
  let isActive = facet.values.active === 'true';

  const children = facet?.children ? facet.children[0] : null;

  if (children) {
    classes.push('has-children');
    if (!isActive) {
      children.forEach(child => {
        if (child.values.active) {
          isActive = true;
        } else if (child?.children) {
          if (child.children[0].find(grandChild => grandChild.values.active) !== undefined) {
            isActive = true;
          }
        }
      });
    }
  }

  if (isActive) {
    classes.push('active');
  }

  const handleAnalyticsData = () => {
    if (!isActive) {
      const analyticsData = {
        event: 'filter_applied',
        eventCategory: 'filter_applied',
        eventAction: `${id}-${facet.raw_value}`,
        eventLabel: facet.values.value,
        eventParams: {
          filter_category: `${id}-${facet.raw_value}`,
          filter_value: facet.values.value,
        },
      };
      const dlEvent = {
        event: "filter_applied",
        event_params: {
          filter_category: filterCategory,
          filter_value: facet.values.value,
          selection_type: 'filter',
          sort_by_option: sortingOption
        }
      };
      sendIt(analyticsData);
      dlEventSend(dlEvent);
    }
  };

  return (
    <>
      <FormCheck
        type="checkbox"
        label={`${facet.values.value} (${facet.values.count})`}
        id={`${id}-${facet.raw_value}`}
        data-url={facet.url}
        onChange={e => {
          handleAnalyticsData();
          onChange(e);
        }}
        checked={!!facet.values.active}
      />
      {children && isActive && (
        <ul>
          {children.map((child: FacetLink, i: number) => {
            return (
              <li key={`${id}-${facet.raw_value}-${i}`} className={cx(...classes)}>
                <FacetItem id={`${id}-${facet.raw_value}`} facet={child} onChange={onChange} filterCategory={filterCategory} sortingOption={sortingOption} />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default FacetItem;
