import { GatsbyImage } from 'gatsby-plugin-image'; // this import was previously at the top of the file. Putting it back to see if image appears'
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { getFocalPoint } from 'src/utils/mediaHelpers';
// PDFs logos
import eLearningLogo from '../assets/icons/resource-icons/e-learning-icon.png';
import focusedNutritionLogo from '../assets/icons/resource-icons/focused-nutrition-icon-500x240.png';
import lifeStageLogo from '../assets/icons/resource-icons/life-stage-nutrition-icon-500x240.png';
import nutritionConversationsLogo from '../assets/icons/resource-icons/nutrition-conversations-icon-500x240.png';
import nutritionalClinicalLogo from '../assets/icons/resource-icons/nutritional-clinical-assesment-tools-icon-500x240.png';
import therapeuticNutritionLogo from '../assets/icons/resource-icons/therapeutic-nutrition-icon-500x240.png';
import understandingPetLogo from '../assets/icons/resource-icons/understanding-pet-food-icon-500x240.png';
import { CMS_OVERLAY, CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';
import { dlEventSend } from 'gatsby-plugin-purina-analytics/common/functions';

const HeroContainer = styled(Container)<{
  cmsTheme: CMS_THEME;
  cmsOverlayTheme: CMS_OVERLAY;
  restrictForeground: boolean;
}>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.alternate};

  img {
    max-width: 100%;
  }

  .hero-card {
    background-color: ${({ cmsTheme }) => theme[cmsTheme].background.alternate};
  }

  .caption {
    color: #fff;
    position: absolute;
    bottom: 32px;
    right: 32px;
  }

  .caption-right {
    right: auto;
    left: 32px;
  }

  &.skrim {
    .hero-card {
      background-color: transparent;
    }

    .gatsby-image-wrapper::after {
      opacity: 0;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
    }
  }

  .gatsby-image-wrapper {
    &.extra-short {
      min-height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '200px')};
    }

    &.short {
      min-height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '240px')};
    }

    &.tall {
      min-height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '280px')};
    }
  }

  .hero-content {
    position: relative;
  }

  .hero-card {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
  }

  &.skrim {
    .gatsby-image-wrapper::after {
      display: none;
    }
  }

  .body {
    font-size: 1.125rem;
  }

  // Tablet.
  @media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.lg}) {
    .gatsby-image-wrapper {
      &.extra-short {
        min-height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '240px')};
      }

      &.short {
        min-height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '360px')};
      }

      &.tall {
        min-height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '400px')};
      }
    }
  }

  // Desktop.
  @media (min-width: ${theme.media.lg}) {
    .hero-content.overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .hero-card {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }

    .gatsby-image-wrapper {
      &.exra-short {
        height: 240px;
      }

      &.short {
        height: 480px;
      }

      &.tall {
        height: 480px;
      }
    }
    &.skrim {
      .gatsby-image-wrapper::after {
        display: block;
      }
    }
  }
  // Large Desktop.
  @media (min-width: ${theme.media.xl}) {
    .gatsby-image-wrapper {
      &.extra-short {
        min-height: 400px;
      }

      &.short {
        min-height: 680px;
      }

      &.tall {
        min-height: 840px;
      }
    }
  }

  &.wide {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    > .container-lg {
      max-width: unset;
    }

    .gatsby-image-wrapper {
      border-radius: unset !important;
    }

    .hero-media {
      padding-left: 0;
      padding-right: 0;
    }

    // Small up.
    @media (max-width: ${theme.media.lg}) {
      .hero-content {
        padding: 0;
      }
    }
    // Large up.
    @media (min-width: ${theme.media.lg}) {
      .gatsby-image-wrapper {
        &.extra-short {
          min-height: 300px;
        }

        &.short {
          min-height: 680px;
        }

        &.tall {
          min-height: 720px;
        }
      }
    }
    // X-large up.
    @media (min-width: ${theme.media.xl}) {
      .gatsby-image-wrapper {
        &.extra-short {
          min-height: 320px;
        }

        &.short {
          min-height: 500px;
        }

        &.tall {
          min-height: 840px;
        }
      }
    }
  }

  .image-right {
    right: 50%;
    transform: translateX(50%);

    @media (min-width: ${theme.media.md}) {
    }
    @media (min-width: ${theme.media.lg}) {
      right: 0;
      transform: translateX(0);
    }
  }

  .image-left {
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: ${theme.media.lg}) {
      left: 0;
      transform: translateX(0);
    }
  }

  @media print {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .printLogo {
    height: 80px;
    max-width: 220px;
    object-fit: cover;
    width: 100%;
    margin-bottom: -24px;
    margin-left: -16px;
  }

  .printBorder {
    border-bottom: 3px solid #e91c24;
  }
`;

const TextContainer = styled.div<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => {
    return theme[cmsTheme].text.default;
  }};
  ul > li {
    color: ${({ cmsTheme }) => {
      return theme[cmsTheme].text.default;
    }};
  }
`;

const ForegroundImageContainer = styled.div<{ cmsTheme: CMS_THEME; restrictForeground: boolean }>`
  position: absolute;
  .gatsby-image-wrapper {
    &.extra-short {
      height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '200px')};
    }

    &.short {
      height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '180px')};

      @media (max-width: ${theme.media.lg}) {
        img {
          max-height: 300px;
          margin: 4vw 0;
        }
      }
      @media (max-width: ${theme.media.sm}) {
        img {
          max-height: 220px;
          margin: 3vw 0;
        }
      }
    }

    &.tall {
      height: ${({ restrictForeground }) => (restrictForeground ? '180px' : '250px')};
    }
  }

  @media (min-width: ${theme.media.lg}) {
    max-width: ${({ restrictForeground }) => (restrictForeground ? '320px' : '40%')};
    top: 50%;
    transform: translateY(-50%) !important;
  }
`;

interface StorageCardProps {
  node: IStorageHeroCard;
  props: any;
}

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    hero_variant_type: 'tall',
    content_position: 'left',
    content_card: false,
    fluid: true,
    theme: CMS_THEME.LIGHT,
    overlay: CMS_OVERLAY.DARK,
  },
  entity_buttons: {
    primary_button: 'btn',
  },
};

// eslint-disable-next-line complexity
const StorageHeroCard: React.FC<StorageCardProps> = function ({ node: heroCard, props }) {
  const settings = Object.assign(DefaultSettings, heroCard?.behaviors || {});
  const direction = settings?.entity_variant?.content_position;
  const hasCard = settings?.entity_variant?.content_card;
  const isFullWidth = settings?.entity_variant?.fluid;
  const imageHeight = settings?.entity_variant?.hero_variant_type;
  const cmsTheme = heroCard.behaviors?.entity_variant?.theme || CMS_THEME.LIGHT;
  const cmsOverlayTheme = heroCard.behaviors?.entity_variant?.overlay || CMS_OVERLAY.LIGHT;
  const primaryButtonVariation = settings.entity_buttons.primary_button;
  const HeadingElement = settings?.entity_heading?.element || 'h2';
  const SubheadingElement = settings?.entity_subheading?.element || 'h2';
  const headingStyle = settings?.entity_heading?.style || 'typography_h2';
  const subheadingStyle = settings?.entity_heading?.style || 'typography_h2';

  const hero_image =
    heroCard.relationships?.image[0]?.relationships?.field_media_image?.gatsbyImage;
  const logo_image = heroCard.relationships?.logo[0];
  const allowedTitles: Record<string, string> = {
    'LIFE STAGE NUTRITION': lifeStageLogo,
    'FOCUSED NUTRITION': focusedNutritionLogo,
    'E-LEARNING': eLearningLogo,
    'NUTRITION CONVERSATIONS': nutritionConversationsLogo,
    'NUTRITIONAL CLINICAL ASSESMENT TOOLS': nutritionalClinicalLogo,
    'THERAPEUTIC NUTRITION': therapeuticNutritionLogo,
    'UNDERSTANDING PET FOOD': understandingPetLogo,
  };

  let titleIcon;
  if (props && props?.bucket) {
    titleIcon = allowedTitles[props.bucket?.toUpperCase() || ''];
  }

  const hasContent =
    heroCard?.title ||
    heroCard?.subtitle ||
    heroCard?.body?.processed ||
    heroCard?.links?.length > 0;

  return (
    <HeroContainer
      data-id={heroCard.drupal_id}
      className={cx(
        'px-0',
        isFullWidth && 'wide',
        !settings?.entity_variant?.content_card && 'skrim',
        `${isFullWidth ? 'py-0' : 'py-3 py-lg-5'}`,
      )}
      fluid
      cmsTheme={cmsTheme}
      cmsOverlayTheme={cmsOverlayTheme}
      restrictForeground={cmsOverlayTheme === 'restrict_foreground'}
    >
      <Container fluid="lg">
        <Row
          className={cx(
            settings?.entity_variant?.content_position === 'right' ? 'flex-row-reverse' : null,
            'position-relative',
          )}
        >
          <Col xs={12} className="position-relative hero-media no-print">
            {hero_image && (
              <GatsbyImage
                alt={heroCard.relationships.image[0].field_media_image?.alt}
                image={hero_image}
                className={cx('rounded-2', imageHeight)}
                objectFit="cover"
                objectPosition={getFocalPoint(heroCard.relationships.image[0])}
              />
            )}
            {heroCard?.caption && (
              <p className={cx('caption', direction === 'right' && 'caption-right')}>
                {heroCard.caption.toUpperCase()}
              </p>
            )}
          </Col>

          {logo_image && (
            <ForegroundImageContainer
              cmsTheme={cmsTheme}
              restrictForeground={cmsOverlayTheme === 'restrict_foreground'}
              className={cx(
                direction === 'right' ? 'image-left' : 'image-right',
                direction === 'right' ? 'ps-lg-5' : 'pe-lg-5',
                'no-print',
                imageHeight,
              )}
            >
              <GatsbyImage
                alt={logo_image?.field_media_image?.alt}
                image={logo_image?.relationships.field_media_image?.gatsbyImage}
                objectFit="contain"
                className={cx(imageHeight)}
              />
            </ForegroundImageContainer>
          )}

          <Col
            xs={12}
            lg={{ span: 7, offset: direction === 'right' ? 5 : 0 }}
            xl={{ span: 6, offset: direction === 'right' ? 6 : 0 }}
            className={cx(
              'hero-content overlay d-flex flex-column justify-content-center align-content-center overflow-hidden',
              direction === 'right' ? 'pe-lg-5' : 'ps-lg-5',
              hasCard && 'as-card',
            )}
          >
            <div
              className={`d-flex justify-content-between align-content-center my-2 py-2 printBorder ${
                heroCard.indexOnPage === 0 && titleIcon ? 'print-only' : 'd-none'
              }`}
            >
              <img
                className="object-fit-cover printLogo"
                src="https://test-purinainstitute-usa-h20.pantheonsite.io/sites/default/files/2023-11/PI_Primary_logo_0.svg?u=https%3A%2F%2Ftest-purinainstitute-usa-h20.pantheonsite.io%2Fsites%2Fdefault%2Ffiles%2F2023-11%2FPI_Primary_logo_0.svg"
                alt="logo"
                height={84}
              />
              <h2 className="fw-light mt-4">{props?.bucket?.toUpperCase()}</h2>
            </div>

            <p
              className={`text-center category-name mt-3 ${
                heroCard.indexOnPage === 0 && props?.category ? 'print-only' : 'd-none'
              }`}
            >
              {props?.category}
            </p>

            {imageHeight !== 'full' && hasContent && (
              <div className="rounded-2 p-3 hero-card no-print">
                <div className="mb-5">
                  <TextContainer cmsTheme={cmsTheme}>
                    {heroCard?.title && (
                      <HeadingElement className={headingStyle}>{heroCard.title}</HeadingElement>
                    )}
                    {heroCard?.subtitle && (
                      <SubheadingElement className={subheadingStyle}>
                        {heroCard.subtitle}
                      </SubheadingElement>
                    )}
                    {heroCard?.body?.processed && (
                      <RichText
                        cmsTheme={cmsTheme}
                        className={cx('mt-3')}
                        body={heroCard.body.processed}
                      />
                    )}
                  </TextContainer>
                </div>

                <ButtonDiadWrapper>
                  {heroCard.links.map((linkObject, index) => {
                    const dlEvent = {
                      event: "select_content",
                      event_params: {
                        cta_location: heroCard.name,
                        link_url: linkObject?.url ? linkObject.url  : '',
                        content_type: heroCard.type,
                        content_name: linkObject?.title ? linkObject.title : ''
                      }
                    }
                    return (
                      <AnalyticsPoint
                        type="component"
                        node={{
                          title: linkObject.title,
                          name: linkObject.title,
                          type: 'hero_link',
                          drupal_id: '',
                        }}
                        as={ButtonLink}
                        key={linkObject.url}
                        variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
                        to={linkObject.url}
                        cmsTheme={cmsTheme}
                        onClick={() => dlEventSend(dlEvent)}
                        {...linkObject?.options?.attributes}
                      >
                        {linkObject.title}
                      </AnalyticsPoint>
                    );
                  })}
                </ButtonDiadWrapper>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </HeroContainer>
  );
};

export default StorageHeroCard;
