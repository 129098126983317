import { css } from '@linaria/core';
import React from 'react';
import { getHtmlAttributes } from 'src/utils/paragraphHelpers';
import { getIcon } from 'src/utils/textToIcons';
import Link from './Link';
import LyticsSignUp from './LyticsSignUp';
import { dlEventSend } from 'gatsby-plugin-purina-analytics/common/functions';

interface LinkListItemProps {
  link: LinkProps;
  border?: boolean;
  center?: boolean;
  setShowMenu?: Function;
  dlEvent?: Object|undefined;
}

const LinkListItem = ({ link, border, center, setShowMenu, dlEvent }: LinkListItemProps) => {
  const attributes = getHtmlAttributes(link.options?.attributes);
  const Icon = getIcon(link.title);

  const iconClass = css`
    margin-left: 0.5rem;
  `;

  const liClass = css`
    @media (min-width: 992px) {
      border-right: 1px solid #fff;
    }
  `;

  const lyticsClass = css`
    color: #fff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `;

  const linkClass = css`
    @media (min-width: 992px) {
      width: auto;
      margin: auto;
      text-align: center;
    }
  `;

  if (attributes?.sign_up) {
    return (
      <li
        key={link.title}
        className={`py-1 px-lg-3 d-flex ${lyticsClass}`}
        onClick={() => setShowMenu && setShowMenu(false)}
      >
        <LyticsSignUp>
          <div {...attributes} className="align-items-center d-flex justify-content-center h-100">
            {link.title} {Icon && <Icon className={iconClass} />}
          </div>
        </LyticsSignUp>
      </li>
    );
  }

  return (
    <li key={link.title} className={`py-1 px-lg-3 d-flex ${border && liClass}`} onClick={() => dlEventSend(dlEvent)}>
      {link.uri === 'route:<nolink>' ? (
        <div {...attributes}>{link.title}</div>
      ) : (
        <Link to={link.url} {...attributes} className={`align-items-center ${center && linkClass}`}>
          {link.title} {Icon && <Icon className={iconClass} />}
        </Link>
      )}
    </li>
  );
};

export default LinkListItem;
